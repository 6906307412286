
@media only screen and (max-width: 990px) {
  .post {
    margin-right: 3rem;
    margin-left: 3rem;
  }
}

@media only screen and (min-width: 991px) {
  .post {
    margin-right: 10rem;
    margin-left: 10rem;
  }
}